import styles from "./PrivacyPolicy.module.css";

function PrivacyPolicy() {
  return (
    <div className={styles.privacyPolicy}>
      <h1 id={styles.headline}>Privacy Policy</h1>
      <p className={styles.ppText}>
        This privacy policy applies between you, the User of this Website and Our Song Request Ltd,
        the owner and provider of this Website. Our Song Request Ltd takes the privacy of your
        information very seriously. This privacy policy applies to our use of any and all Data
        collected by us or provided by you in relation to your use of the Website. Please read this
        privacy policy carefully. Definitions and interpretation
      </p>
      <div className={styles.mainText}>
        <div className={styles.paragraph}>
          <h3 id={styles.paragraphHeadline}>
            1. In this privacy policy, the following definitions are used:
          </h3>
          <p id={styles.paragraphText}>
            <p>
              Data collectively all information that you submit to Our Song Request Ltd via the
              Website. This definition incorporates, where applicable, the definitions provided in
              the Data Protection Laws;
            </p>
            <p>
              Cookies a small text file placed on your computer by this Website when you visit
              certain parts of the Website and/or when you use certain features of the Website.
              Details of the cookies used by this Website are set out in the clause below (
              Cookies); Data Protection Laws any applicable law relating to the processing of
              personal Data, including but not limited to the Directive 96/46/EC (Data Protection
              Directive) or the GDPR, and any national implementing laws, regulations and secondary
              legislation, for as long as the GDPR is effective in the UK;
            </p>
            <p>GDPR the General Data Protection Regulation (EU) 2016/679;</p>
            <p>Our Song Request Ltd, we or us Our Song Request Ltd;</p>
            <p>
              UK and EU Cookie Law the Privacy and Electronic Communications (EC Directive)
              Regulations 2003 as amended by the Privacy and Electronic Communications (EC
              Directive) (Amendment) Regulations 2011;
            </p>
            <p>
              User or you any third party that accesses the Website and is not either (i) employed
              by Our Song Request Ltd and acting in the course of their employment or (ii) engaged
              as a consultant or otherwise providing services to Our Song Request Ltd and accessing
              the Website in connection with the provision of such services;
            </p>
            <p>
              and Website the website that you are currently using, www.oursongrequest.com, and any
              sub-domains of this site unless expressly excluded by their own terms and conditions.
            </p>
          </p>
        </div>
        <div className={styles.paragraph}>
          <h3 id={styles.paragraphHeadline}>
            2. In this privacy policy, unless the context requires a different interpretation:
          </h3>
          <p id={styles.paragraphText}>
            <p>a. the singular includes the plural and vice versa;</p>
            <p>
              b. references to sub-clauses, clauses, schedules or appendices are to sub-clauses,
              clauses, schedules or appendices of this privacy policy
            </p>
            <p>
              c. a reference to a person includes firms, companies, government entities, trusts and
              partnerships;
            </p>
            <p>d. "including" is understood to mean "including without limitation";</p>
            <p>
              e. reference to any statutory provision includes any modification or amendment of it;
            </p>
            <p>
              f. the headings and sub-headings do not form part of this privacy policy. Scope of
              this privacy policy
            </p>
          </p>
        </div>
        <div className={styles.paragraph}>
          <h3 id={styles.paragraphHeadline}>3. </h3>
          <p id={styles.paragraphText}>
            This privacy policy applies only to the actions of Our Song Request Ltd and Users with
            respect to this Website. It does not extend to any websites that can be accessed from
            this Website including, but not limited to, any links we may provide to social media
            websites.
          </p>
        </div>
        <div className={styles.paragraph}>
          <h3 id={styles.paragraphHeadline}>4.</h3>
          <p id={styles.paragraphText}>
            For purposes of the applicable Data Protection Laws, Our Song Request Ltd is the "data
            controller". This means that Our Song Request Ltd determines the purposes for which, and
            the manner in which, your Data is processed. Data collected
          </p>
        </div>

        <div className={styles.paragraph}>
          <h3 id={styles.paragraphHeadline}>
            5. We may collect the following Data, which includes personal Data, from you:
          </h3>
          <p id={styles.paragraphText}>
            <p>a. name;</p>
            <p>b. date of birth;</p>
            <p></p>
            c. gender;
            <p>d. job title;</p>
            <p>e. profession;</p>
            <p>f. contact Information such as email addresses and telephone numbers;</p>
            <p>g. demographic information such as postcode, preferences and interests;</p>
            <p>h. financial information such as credit / debit card numbers;</p>
            <p>i. IP address (automatically collected);</p>
            <p>j. web browser type and version (automatically collected);</p>
            <p>k. operating system (automatically collected);</p>
            <p>
              l. a list of URLs starting with a referring site, your activity on this Website, and
              the site you exit to (automatically collected);
            </p>
            <p>in each case, in accordance with this privacy policy.</p>
          </p>
        </div>

        <div className={styles.paragraph}>
          <h3 id={styles.paragraphHeadline}>6. We collect Data in the following ways:</h3>
          <p id={styles.paragraphText}>
            <p>a. data is given to us by you ;</p>
            <p>b. data is collected automatically. Data that is given to us by you</p>
          </p>
        </div>

        <div className={styles.paragraph}>
          <h3 id={styles.paragraphHeadline}>
            7. Our Song Request Ltd will collect your Data in a number of ways, for example:
          </h3>
          <p id={styles.paragraphText}>
            <p>
              a. when you contact us through the Website, by telephone, post, e-mail or through any
              other means;
              <p>
                b. when you register with us and set up an account to receive our products/services;
              </p>
              <p>
                c. when you complete surveys that we use for research purposes (although you are not
                obliged to respond to them);{" "}
              </p>{" "}
              <p>d. when you enter a competition or promotion through a social media channel;</p>
              <p>e. when you make payments to us, through this Website or otherwise; </p>
              <p>f. when you elect to receive marketing communications from us;</p>
              <p>
                g. when you use our services; in each case, in accordance with this privacy policy.
                Data that is collected automatically
              </p>
            </p>
          </p>
        </div>

        <div className={styles.paragraph}>
          <h3 id={styles.paragraphHeadline}>
            8. To the extent that you access the Website, we will collect your Data automatically,
            for example:
          </h3>
          <p id={styles.paragraphText}>
            <p>
              a. we automatically collect some information about your visit to the Website. This
              information helps us to make improvements to Website content and navigation, and
              includes your IP address, the date, times and frequency with which you access the
              Website and the way you use and interact with its content.
            </p>
            <p>
              b. we will collect your Data automatically via cookies, in line with the cookie
              settings on your browser. For more information about cookies, and how we use them on
              the Website, see the section below, headed "Cookies". Our use of Data
            </p>
          </p>
        </div>

        <div className={styles.paragraph}>
          <h3 id={styles.paragraphHeadline}>
            9. Any or all of the above Data may be required by us from time to time in order to
            provide you with the best possible service and experience when using our Website.
            Specifically, Data may be used by us for the following reasons:
          </h3>
          <p id={styles.paragraphText}>
            <p>a. internal record keeping;</p>
            <p>b. improvement of our products / services;</p>
            <p>c. transmission by email of marketing materials that may be of interest to you;</p>
            <p>
              d. contact for market research purposes which may be done using email, telephone, fax
              or mail. Such information may be used to customise or update the Website; in each
              case, in accordance with this privacy policy.
            </p>
          </p>
        </div>

        <div className={styles.paragraph}>
          <h3 id={styles.paragraphHeadline}>10.</h3>
          <p id={styles.paragraphText}>
            We may use your Data for the above purposes if we deem it necessary to do so for our
            legitimate interests. If you are not satisfied with this, you have the right to object
            in certain circumstances (see the section headed "Your rights" below).
          </p>
        </div>

        <div className={styles.paragraph}>
          <h3 id={styles.paragraphHeadline}>
            11. For the delivery of direct marketing to you via e-mail, we'll need your consent,
            whether via an opt-in or soft-opt-in:
          </h3>
          <p id={styles.paragraphText}>
            <p>
              a. soft opt-in consent is a specific type of consent which applies when you have
              previously engaged with us (for example, you contact us to ask us for more details
              about a particular product/service, and we are marketing similar products/services).
              Under "soft opt-in" consent, we will take your consent as given unless you opt-out.
            </p>
            <p>
              b. for other types of e-marketing, we are required to obtain your explicit consent;
              that is, you need to take positive and affirmative action when consenting by, for
              example, checking a tick box that we'll provide.
            </p>
            <p>
              c. if you are not satisfied about our approach to marketing, you have the right to
              withdraw consent at any time. To find out how to withdraw your consent, see the
              section headed "Your rights" below.
            </p>
          </p>
        </div>

        <div className={styles.paragraph}>
          <h3 id={styles.paragraphHeadline}>12.</h3>
          <p id={styles.paragraphText}>
            When you register with us and set up an account to receive our services, the legal basis
            for this processing is the performance of a contract between you and us and/or taking
            steps, at your request, to enter into such a contract. c. if you are not satisfied about
            our approach to marketing, you have the right to withdraw consent at any time. To find
            out how to withdraw your consent, see the section headed "Your rights" below.
          </p>
        </div>

        <div className={styles.paragraph}>
          <h3 id={styles.paragraphHeadline}>13.</h3>
          <p id={styles.paragraphText}>
            We may use your Data to show you Our Song Request Ltd adverts and other content on other
            websites. If you do not want us to use your data to show you Our Song Request Ltd
            adverts and other content on other websites, please turn off the relevant cookies
            (please refer to the section headed "Cookies" below).
          </p>
        </div>

        <div className={styles.paragraph}>
          <h3 id={styles.paragraphHeadline}>
            14. We may share your Data with the following groups of people for the following
            reasons:
          </h3>
          <p id={styles.paragraphText}>
            <p>a. any of our group companies or affiliates - For marketing;</p>

            <p>b. our employees, agents and/or professional advisors - To provide the service;</p>

            <p>
              c. third party service providers who provide services to us which require the
              processing of personal data - For systems support;
            </p>

            <p>
              d. third party payment providers who process payments made over the Website - To
              provide a payment service;
            </p>

            <p>
              e. relevant authorities - Upon request from the relevant authorities; in each case, in
              accordance with this privacy policy.
            </p>
          </p>
        </div>

        <div className={styles.paragraph}>
          <h3 id={styles.paragraphHeadline}>
            15. We will use technical and organisational measures to safeguard your Data, for
            example:
          </h3>
          <p id={styles.paragraphText}>
            <p>
              a. access to your account is controlled by a password and a user name that is unique
              to you.
            </p>
            <p>b. we store your Data on secure servers.</p>
            <p>
              c. payment details are encrypted using SSL technology (typically you will see a lock
              icon or green address bar (or both) in your browser when we use this technology.
            </p>
          </p>
        </div>

        <div className={styles.paragraph}>
          <h3 id={styles.paragraphHeadline}>16.</h3>
          <p id={styles.paragraphText}>
            Technical and organisational measures include measures to deal with any suspected data
            breach. If you suspect any misuse or loss or unauthorised access to your Data, please
            let us know immediately by contacting us via this e-mail address:
            hello@oursongrequest.com.
          </p>
        </div>

        <div className={styles.paragraph}>
          <h3 id={styles.paragraphHeadline}>17.</h3>
          <p id={styles.paragraphText}>
            If you want detailed information from Get Safe Online on how to protect your information
            and your computers and devices against fraud, identity theft, viruses and many other
            online problems, please visit www.getsafeonline.org. Get Safe Online is supported by HM
            Government and leading businesses. Data retention
          </p>
        </div>

        <div className={styles.paragraph}>
          <h3 id={styles.paragraphHeadline}>
            11. For the delivery of direct marketing to you via e-mail, we'll need your consent,
            whether via an opt-in or soft-opt-in:
          </h3>
          <p id={styles.paragraphText}>
            <p>
              a. soft opt-in consent is a specific type of consent which applies when you have
              previously engaged with us (for example, you contact us to ask us for more details
              about a particular product/service, and we are marketing similar products/services).
              Under "soft opt-in" consent, we will take your consent as given unless you opt-out.
            </p>
            <p>
              b. for other types of e-marketing, we are required to obtain your explicit consent;
              that is, you need to take positive and affirmative action when consenting by, for
              example, checking a tick box that we'll provide.
            </p>
            <p>
              c. if you are not satisfied about our approach to marketing, you have the right to
              withdraw consent at any time. To find out how to withdraw your consent, see the
              section headed "Your rights" below.
            </p>
          </p>
        </div>

        <div className={styles.paragraph}>
          <h3 id={styles.paragraphHeadline}>18.</h3>
          <p id={styles.paragraphText}>
            Unless a longer retention period is required or permitted by law, we will only hold your
            Data on our systems for the period necessary to fulfil the purposes outlined in this
            privacy policy or until you request that the Data be deleted.
          </p>
        </div>

        <div className={styles.paragraph}>
          <h3 id={styles.paragraphHeadline}>19.</h3>
          <p id={styles.paragraphText}>
            Even if we delete your Data, it may persist on backup or archival media for legal, tax
            or regulatory purposes.
          </p>
        </div>

        <div className={styles.paragraph}>
          <h3 id={styles.paragraphHeadline}>
            20. You have the following rights in relation to your Data:
          </h3>
          <p id={styles.paragraphText}>
            <p>
              a. Right to access - the right to request (i) copies of the information we hold about
              you at any time, or (ii) that we modify, update or delete such information. If we
              provide you with access to the information we hold about you, we will not charge you
              for this, unless your request is "manifestly unfounded or excessive." Where we are
              legally permitted to do so, we may refuse your request. If we refuse your request, we
              will tell you the reasons why.
            </p>
            <p>
              b. Right to correct - the right to have your Data rectified if it is inaccurate or
              incomplete.
            </p>
            <p>
              c. Right to erase - the right to request that we delete or remove your Data from our
              systems.
            </p>
            <p>
              d. Right to restrict our use of your Data - the right to "block" us from using your
              Data or limit the way in which we can use it.
            </p>
            <p>
              e. Right to data portability - the right to request that we move, copy or transfer
              your Data.
            </p>
            <p>
              f. Right to object - the right to object to our use of your Data including where we
              use it for our legitimate interests.
            </p>
          </p>
        </div>

        <div className={styles.paragraph}>
          <h3 id={styles.paragraphHeadline}>21.</h3>
          <p id={styles.paragraphText}>
            To make enquiries, exercise any of your rights set out above, or withdraw your consent
            to the processing of your Data (where consent is our legal basis for processing your
            Data), please contact us via this e-mail address: hello@oursongrequest.com.
          </p>
        </div>

        <div className={styles.paragraph}>
          <h3 id={styles.paragraphHeadline}>22.</h3>
          <p id={styles.paragraphText}>
            If you are not satisfied with the way a complaint you make in relation to your Data is
            handled by us, you may be able to refer your complaint to the relevant data protection
            authority. For the UK, this is the Information Commissioner's Office (ICO). The ICO's
            contact details can be found on their website at https://ico.org.uk/.
          </p>
        </div>

        <div className={styles.paragraph}>
          <h3 id={styles.paragraphHeadline}>23.</h3>
          <p id={styles.paragraphText}>
            It is important that the Data we hold about you is accurate and current. Please keep us
            informed if your Data changes during the period for which we hold it.
          </p>
        </div>

        <div className={styles.paragraph}>
          <h3 id={styles.paragraphHeadline}>24.</h3>
          <p id={styles.paragraphText}>
            This Website may, from time to time, provide links to other websites. We have no control
            over such websites and are not responsible for the content of these websites. This
            privacy policy does not extend to your use of such websites. You are advised to read the
            privacy policy or statement of other websites prior to using them. Changes of business
            ownership and control
          </p>
        </div>

        <div className={styles.paragraph}>
          <h3 id={styles.paragraphHeadline}>25.</h3>
          <p id={styles.paragraphText}>
            Our Song Request Ltd may, from time to time, expand or reduce our business and this may
            involve the sale and/or the transfer of control of all or part of Our Song Request Ltd.
            Data provided by Users will, where it is relevant to any part of our business so
            transferred, be transferred along with that part and the new owner or newly controlling
            party will, under the terms of this privacy policy, be permitted to use the Data for the
            purposes for which it was originally supplied to us.
          </p>
        </div>

        <div className={styles.paragraph}>
          <h3 id={styles.paragraphHeadline}>26.</h3>
          <p id={styles.paragraphText}>
            We may also disclose Data to a prospective purchaser of our business or any part of it.
          </p>
        </div>

        <div className={styles.paragraph}>
          <h3 id={styles.paragraphHeadline}>27.</h3>
          <p id={styles.paragraphText}>
            In the above instances, we will take steps with the aim of ensuring your privacy is
            protected. Cookies
          </p>
        </div>

        <div className={styles.paragraph}>
          <h3 id={styles.paragraphHeadline}>28.</h3>
          <p id={styles.paragraphText}>
            This Website may place and access certain Cookies on your computer. Our Song Request Ltd
            uses Cookies to improve your experience of using the Website and to improve our range of
            products. Our Song Request Ltd has carefully chosen these Cookies and has taken steps to
            ensure that your privacy is protected and respected at all times.
          </p>
        </div>

        <div className={styles.paragraph}>
          <h3 id={styles.paragraphHeadline}>29.</h3>
          <p id={styles.paragraphText}>
            All Cookies used by this Website are used in accordance with current UK and EU Cookie
            Law.
          </p>
        </div>

        <div className={styles.paragraph}>
          <h3 id={styles.paragraphHeadline}>30.</h3>
          <p id={styles.paragraphText}>
            Before the Website places Cookies on your computer, you will be presented with a message
            bar requesting your consent to set those Cookies. By giving your consent to the placing
            of Cookies, you are enabling Our Song Request Ltd to provide a better experience and
            service to you. You may, if you wish, deny consent to the placing of Cookies; however
            certain features of the Website may not function fully or as intended.
          </p>
        </div>

        <div className={styles.paragraph}>
          <h3 id={styles.paragraphHeadline}>31.</h3>
          <p id={styles.paragraphText}>
            This Website may place the following Cookies: Type of Cookie Purpose Strictly necessary
            cookies These are cookies that are required for the operation of our website. They
            include, for example, cookies that enable you to log into secure areas of our website,
            use a shopping cart or make use of e-billing services. Analytical/performance cookies
            They allow us to recognise and count the number of visitors and to see how visitors move
            around our website when they are using it. This helps us to improve the way our website
            works, for example, by ensuring that users are finding what they are looking for easily.
            Functionality cookies These are used to recognise you when you return to our website.
            This enables us to personalise our content for you, greet you by name and remember your
            preferences (for example, your choice of language or region). Targeting cookies These
            cookies record your visit to our website, the pages you have visited and the links you
            have followed. We will use this information to make our website and the advertising
            displayed on it more relevant to your interests. We may also share this information with
            third parties for this purpose.
          </p>
        </div>

        <div className={styles.paragraph}>
          <h3 id={styles.paragraphHeadline}>32.</h3>
          <p id={styles.paragraphText}>
            You can find a list of Cookies that we use in the Cookies Schedule.
          </p>
        </div>

        <div className={styles.paragraph}>
          <h3 id={styles.paragraphHeadline}>33.</h3>
          <p id={styles.paragraphText}>
            You can choose to enable or disable Cookies in your internet browser. By default, most
            internet browsers accept Cookies but this can be changed. For further details, please
            consult the help menu in your internet browser.
          </p>
        </div>

        <div className={styles.paragraph}>
          <h3 id={styles.paragraphHeadline}>34.</h3>
          <p id={styles.paragraphText}>
            You can choose to delete Cookies at any time; however you may lose any information that
            enables you to access the Website more quickly and efficiently including, but not
            limited to, personalisation settings.
          </p>
        </div>

        <div className={styles.paragraph}>
          <h3 id={styles.paragraphHeadline}>35.</h3>
          <p id={styles.paragraphText}>
            It is recommended that you ensure that your internet browser is up-to-date and that you
            consult the help and guidance provided by the developer of your internet browser if you
            are unsure about adjusting your privacy settings.
          </p>
        </div>

        <div className={styles.paragraph}>
          <h3 id={styles.paragraphHeadline}>36.</h3>
          <p id={styles.paragraphText}>
            For more information generally on cookies, including how to disable them, please refer
            to aboutcookies.org. You will also find details on how to delete cookies from your
            computer.
          </p>
        </div>

        <div className={styles.paragraph}>
          <h3 id={styles.paragraphHeadline}>37.</h3>
          <p id={styles.paragraphText}>
            37. You may not transfer any of your rights under this privacy policy to any other
            person. We may transfer our rights under this privacy policy where we reasonably believe
            your rights will not be affected.
          </p>
        </div>

        <div className={styles.paragraph}>
          <h3 id={styles.paragraphHeadline}>38.</h3>
          <p id={styles.paragraphText}>
            If any court or competent authority finds that any provision of this privacy policy (or
            part of any provision) is invalid, illegal or unenforceable, that provision or
            part-provision will, to the extent required, be deemed to be deleted, and the validity
            and enforceability of the other provisions of this privacy policy will not be affected.
          </p>
        </div>

        <div className={styles.paragraph}>
          <h3 id={styles.paragraphHeadline}>39.</h3>
          <p id={styles.paragraphText}>
            Unless otherwise agreed, no delay, act or omission by a party in exercising any right or
            remedy will be deemed a waiver of that, or any other, right or remedy.
          </p>
        </div>

        <div className={styles.paragraph}>
          <h3 id={styles.paragraphHeadline}>40.</h3>
          <p id={styles.paragraphText}>
            This Agreement will be governed by and interpreted according to the law of England and
            Wales. All disputes arising under the Agreement will be subject to the exclusive
            jurisdiction of the English and Welsh courts. Changes to this privacy policy
          </p>
        </div>

        <div className={styles.paragraph}>
          <h3 id={styles.paragraphHeadline}>41.</h3>
          <p id={styles.paragraphText}>
            Our Song Request Ltd reserves the right to change this privacy policy as we may deem
            necessary from time to time or as may be required by law. Any changes will be
            immediately posted on the Website and you are deemed to have accepted the terms of the
            privacy policy on your first use of the Website following the alterations.
          </p>
        </div>
      </div>
      <p> You may contact Our Song Request Ltd by email at hello@oursongrequest.com.</p>
    </div>
  );
}

export default PrivacyPolicy;
