import { Link } from "react-router-dom";
import styles from "./Footer.module.css";
import FacebookIcon from "../../images/fb.png";
import InstagramIcon from "../../images/instagram.png";
import TikTokIcon from "../../images/tiktok.png";
import EmailIcon from "../../images/email.png";
import WhatsAppIcon from "../../images/whatsapp.png";
import Logo from "../../images/logo-1.png";

function Footer() {
  return (
    <div className={styles.footer}>
      <div className={styles.blocks}>
        <div className={styles.block}>
          <img src={Logo} alt="logo" id={styles.logo} />
          <p>Every life tells a story</p>
          <div className={styles.inline} id={styles.socialMedia}>
            <Link to="https://www.facebook.com/oursongrequest" target="_blank">
              <img src={FacebookIcon} alt="facebook" />
            </Link>
            <Link to="https://www.tiktok.com/@our.song.request" target="_blank">
              <img src={TikTokIcon} alt="tiktok" />
            </Link>
            <Link to="https://www.instagram.com/oursongrequest/" target="_blank">
              <img src={InstagramIcon} alt="instagram" />
            </Link>
          </div>
        </div>
        <div className={styles.block}>
          <h3>Contact</h3>
          <ul>
            <Link to={"/how-it-works"}>How it Works</Link>
            <Link to={"/faq"}>FAQ</Link>
            <Link to={"/tos"}>Terms & Conditions</Link>
            <Link to={"privacy-policy"}>Privacy Policy</Link>
          </ul>
          <div className={styles.inline}>
            <img src={WhatsAppIcon} alt="whatsapp" />
            <p>+44 330 043 1781</p>
          </div>
          <div className={styles.inline}>
            <img src={EmailIcon} alt="email" id={styles.email} />
            <a href="mailto:hello@oursongrequest.com">hello@oursongrequest.com</a>
          </div>
        </div>
        <div className={styles.block}>
          <h3>Stay in touch</h3>
          <Link to={"/contact-us"}>
            <button>Contact Us</button>
          </Link>
        </div>
      </div>
      <p id={styles.copyright}>&copy; OSR. All Rights Reserved 2024</p>
    </div>
  );
}

export default Footer;
