import { useRef, useState } from "react";
import { Link } from "react-router-dom";

import styles from "./ArtistItem.module.css";

import Play from "../../images/play.png";
import Pause from "../../images/pause.png";

import Img from "./Img";
import { LazyLoadImage } from "react-lazy-load-image-component";

function ArtistItem({ data, onPlayPause = function () {}, addAudio = false }) {
  const [play, setPlay] = useState(false);
  const audioRef = useRef(0);

  function handleMusicState() {
    document.querySelectorAll("audio").forEach((el) => el.pause());
    document.querySelectorAll("#icon").forEach((el) => (el.src = Play));
    setPlay((current) => !current);
    if (!play) {
      audioRef.current.play();
    }
  }

  return (
    <div className={styles.artistItem}>
      <Link to={`/request-song/${data.id}`}>
        {addAudio && (
          <audio ref={audioRef}>
            <source src={data.audio} type="audio/mpeg" />
          </audio>
        )}

        <Img thumbnail={data.thumbnail} />
      </Link>
      <div className={styles.bottomInfo}>
        <div id={styles.playBtn}>
          <LazyLoadImage
            src={!play ? Play : Pause}
            alt="play"
            onClick={() => (addAudio ? handleMusicState() : onPlayPause(setPlay, play, data.audio))}
            effect="blur"
            width={20}
            height={20}
          />
        </div>
        <p id={styles.artistName}>{data.artist}</p>
      </div>
    </div>
  );
}

export default ArtistItem;
