import { React } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
// import { initGA, logPageView } from "./analytics";
// import PageTracker from "./PageTracker.js";

import { HTML5Backend } from "react-dnd-html5-backend";
import { DndProvider } from "react-dnd";

import Navbar from "./components/Navbar/Navbar";

import Home from "./pages/Home/Home.js";
import RequestOrder from "./pages/RequestOrder/RequestOrder.js";
import PaymentPage from "./pages/RequestOrder/PaymentPage.js";
import PayPalPaymentSuccessPage from "./pages/RequestOrder/PayPalPaymentSuccessPage.js";

import { RequestSongProvider } from "./context/RequestOrderContext.js";
import PayPalPaymentCancelPage from "./pages/RequestOrder/PayPalPaymentCancelPage.js";
import Footer from "./components/Footer/Footer.js";
import HowItWorks from "./pages/HowItWorks/HowItWorks.js";
import Faq from "./pages/FAQ/Faq.js";
import Tos from "./pages/TOS/Tos.js";
import PrivacyPolicy from "./pages/PrivacyPolicy/PrivacyPolicy.js";
import ContactUs from "./pages/ContactUs/ContactUs.js";

function App() {
  // useEffect(() => {
  //   initGA();
  //   logPageView();
  // }, []);

  return (
    <Router>
      <RequestSongProvider>
        <DndProvider backend={HTML5Backend}>
          <Navbar />
          {/* <PageTracker /> */}
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/request-song/:id/" element={<RequestOrder />} />
            <Route path="/payment" element={<PaymentPage />} />
            <Route path="/payment-success" element={<PayPalPaymentSuccessPage />} />
            <Route path="/paypal-cancel" element={<PayPalPaymentCancelPage />} />

            <Route path={"/how-it-works"} element={<HowItWorks />} />
            <Route path={"/faq"} element={<Faq />} />
            <Route path={"/tos"} element={<Tos />} />
            <Route path={"/privacy-policy"} element={<PrivacyPolicy />} />
            <Route path="/contact-us" element={<ContactUs />} />
          </Routes>
          <Footer />
        </DndProvider>
      </RequestSongProvider>
    </Router>
  );
}

export default App;
