import { useParams } from "react-router-dom";
import ArtistItem from "../../components/HomeComponents/ArtistItem";
import styles from "./RequestOrder.module.css";
import { useEffect, useRef, useState } from "react";
import ErrorMessage from "../../components/ReusableComponents/ErrorMessage/ErrorMessage";
import SpinnerFullPage from "../../components/ReusableComponents/FullPageLoader/SpinnerFullPage";
import useSendGetRequest from "../../hooks/useSendGetRequest";
import ImageUploadContainer from "../../components/ReusableComponents/ImageUploadContainer/ImageUploadContainer";
import { useRequestSong } from "../../context/RequestOrderContext";
import acrylicPhoto from "../../images/acrylic.jpeg";

function RequestOrder() {
  const { id } = useParams();

  const { data, loading, errorMsg } = useSendGetRequest(`get-song-detail/${id}/`);
  const { loading: isSendingRequest, errorMessage, sendRequestSongOrder } = useRequestSong();

  const [submitData, setSubmitData] = useState({
    name: "",
    songAtmoshpere: "happy",
    hobbies: "",
    personality: "",
    specificDetails: "",
    contactEmail: "",
    contactPhone: "",
    acrylicPhotoAmount: 0,
    postcode: "",
    houseNumber: "",
    funeralDirector: "",
    songId: id,
  });

  let constantPriceRef = useRef();
  const [file, setFile] = useState();

  const [price, setPrice] = useState(null);
  useEffect(
    function () {
      if (data) {
        setPrice(data[0]["price"]);
        constantPriceRef.current = data[0]["price"];
      }
    },
    [data]
  );

  function acrylicChange(value) {
    const newPrice = +constantPriceRef.current + +value * 35;
    setPrice(newPrice);
  }

  function handleChange(e) {
    const value = e.target.value;
    const name = e.target.name;
    setSubmitData({
      ...submitData,
      [name]: value,
    });
    if (name === "acrylicPhotoAmount") {
      acrylicChange(value);
    }
  }
  function handleSubmit(e) {
    console.log(e);
    e.preventDefault();
    sendRequestSongOrder(submitData, file);
  }

  if (loading || isSendingRequest) return <SpinnerFullPage />;
  if (errorMsg || errorMessage) return <ErrorMessage />;

  return (
    <div>
      <div className={styles.heading}>
        <h1>{data[0].artist} Song Request</h1>
        <ArtistItem data={data[0]} addAudio={true} />
        <p id={styles.price}>£{price}</p>
        {+submitData.acrylicPhotoAmount !== 0 && (
          <p id={styles.explainPrice}>
            £{constantPriceRef.current}
            {Array.from({ length: +submitData.acrylicPhotoAmount }, (_, i) => i + 1).map(
              (num) => " + £35"
            )}
          </p>
        )}
      </div>

      <form className={styles.form} onSubmit={handleSubmit}>
        <div>
          <label>Your loved one's name:</label>
          <input name="name" value={submitData.name} onChange={handleChange} required />
        </div>

        <div>
          <h3>Song Information</h3>
          <label>Atmosphere of song:</label>
          <select name="songAtmoshpere" value={submitData.songAtmoshpere} onChange={handleChange}>
            <option value="happy">Happy</option>
            <option value="somber">Somber</option>
            <option value="reflective">Reflective</option>
          </select>
        </div>

        <div>
          <label>Your loved one's hobbies, interests, or passions:</label>
          <textarea
            placeholder="Dance, painting, gardening"
            rows={5}
            name="hobbies"
            value={submitData.hobbies}
            onChange={handleChange}
          ></textarea>
        </div>

        <div>
          <label>Personality and character traits you'd like the song to reflect:</label>
          <textarea
            placeholder="Generous, caring, and a bit of a perfectionist, he was loyal and protective of his close friends."
            rows={5}
            name="personality"
            value={submitData.personality}
            onChange={handleChange}
          ></textarea>
        </div>

        <div>
          <label>Specific details to include in the song:</label>
          <textarea
            placeholder="He used to love watching Manchester United, enjoying meals with his children (James & Becky) and playing bowls with his best friend John at his local club."
            rows={5}
            name="specificDetails"
            value={submitData.specificDetails}
            onChange={handleChange}
          ></textarea>
        </div>

        <div className={styles.moreFields}>
          <h3>Contact Information</h3>
          <div className={styles.fields}>
            <div>
              <label>Your Email:</label>
              <input
                placeholder="someone@gmail.com"
                name="contactEmail"
                value={submitData.contactEmail}
                onChange={handleChange}
                required
              />
            </div>
            <div>
              <label>Your Phone No.:</label>
              <input
                placeholder="+44 1234 567890"
                name="contactPhone"
                value={submitData.contactPhone}
                onChange={handleChange}
                required
              />
            </div>
          </div>
        </div>
        <div>
          <label>Funeral Director (if applicable)</label>
          <input
            name="funeralDirector"
            value={submitData.funeralDirector}
            onChange={handleChange}
          />
        </div>

        <div>
          <label>Acrylic photo keepsake:</label>
          <select
            onChange={handleChange}
            name="acrylicPhotoAmount"
            value={submitData.acrylicPhotoAmount}
          >
            <option value={0}>None</option>
            <option value={1}>One</option>
            <option value={2}>Two</option>
            <option value={3}>Three</option>
            <option value={4}>Four</option>
            <option value={5}>Five</option>
          </select>
        </div>
        <div className={styles.moreFields}>
          <h3>Shipping Information</h3>
          <div className={styles.fields}>
            <div>
              <label>Postcode:</label>
              <input
                placeholder="Postcode"
                value={submitData.postcode}
                onChange={handleChange}
                name="postcode"
                required={+submitData.acrylicPhotoAmount !== 0 && true}
              />
            </div>

            <div>
              <label>House number or name:</label>
              <input
                placeholder="House number or name"
                value={submitData.houseNumber}
                onChange={handleChange}
                name="houseNumber"
                required={+submitData.acrylicPhotoAmount !== 0 && true}
              />
            </div>
          </div>
        </div>
        {+submitData.acrylicPhotoAmount !== 0 && (
          <div className={styles.acrylicPhotos}>
            {Array.from({ length: +submitData.acrylicPhotoAmount }, (_, i) => i + 1).map((num) => (
              <img src={acrylicPhoto} alt="acrylic" id={styles.acrylicPhoto} />
            ))}
          </div>
        )}

        <ImageUploadContainer onFileChange={setFile} />

        <button className="standard-btn">Continue</button>
      </form>
    </div>
  );
}

export default RequestOrder;
