import styles from "./ArtistList.module.css";
import SpinnerFullPage from "../ReusableComponents/FullPageLoader/SpinnerFullPage";
import ErrorMessage from "../ReusableComponents/ErrorMessage/ErrorMessage";
import useSendGetRequest from "../../hooks/useSendGetRequest";
import Play from "../../images/play.png";
import { useEffect, useRef } from "react";
import ArtistItem from "./ArtistItem";

function ArtistList() {
  const { data, loading, errorMsg } = useSendGetRequest("get-songs-list/");

  const audioRef = useRef(0);

  async function handleScroll() {
    // try {
    //   // setLoading(true);
    //   const response = await fetch(`${backendURL}get-songs-list/`);
    //   const data = await response.json();
    //   if (!response.ok) {
    //     if (data["errorMsg"]) {
    //       throw new Error(data["errorMsg"]);
    //     } else {
    //       throw new Error("Error happened while fetching data.");
    //     }
    //   }
    //   console.log(data["data"]);
    //   // setData(data["data"]);
    //   if (data["successMsg"]) {
    //     // setSuccessMsg(data["successMsg"]);
    //   } else {
    //     // setSuccessMsg("Data fetched successfully!");
    //   }
    // } catch (error) {
    //   // setErrorMsg(error.message);
    // } finally {
    //   // setLoading(false);
    // }
  }
  useEffect(function () {
    window.addEventListener("scroll", handleScroll);
  }, []);

  function handleMusicState(setPlay, play, audio) {
    document.querySelectorAll("audio").forEach((el) => el.pause());
    document.querySelectorAll("#icon").forEach((el) => (el.src = Play));
    setPlay((current) => !current);
    console.log(audioRef.current.src, audio);
    if (audioRef.current.src === audio) {
      audioRef.current.pause();
      audioRef.current.src = "";
      return;
    }
    audioRef.current.src = audio;
    audioRef.current.play();
  }

  if (loading) return <SpinnerFullPage />;
  if (errorMsg) return <ErrorMessage />;

  console.log(data);
  return (
    <div className={styles.artistList}>
      <audio ref={audioRef}>
        <source src={""} type="audio/mpeg" />
      </audio>
      {data.map((item, i) => (
        <ArtistItem key={item.id} data={item} onPlayPause={handleMusicState} />
      ))}
    </div>
  );
}

export default ArtistList;
