import { useState } from "react";
import styles from "./ImageUploadContainer.module.css";

function ImageUploadContainer({ onFileChange }) {
  const [fileName, setFileName] = useState(null);
  const [fileSize, setFileSize] = useState(null);

  function removeFile() {
    setFileName(null);
    setFileSize(null);
  }

  function onDragOver(e) {
    let event = e;
    event.stopPropagation();
    event.preventDefault();
  }

  function onDragEnter(e) {
    let event = e;
    event.stopPropagation();
    event.preventDefault();
  }

  function onFileDrop(e) {
    let event = e;
    event.stopPropagation();
    event.preventDefault();

    const image = event.dataTransfer.files[0];

    const fileName = image.name;
    const fileSize = (image.size / 1024).toFixed(1) + "KB";
    onFileChange(image);
    setFileName(fileName);
    setFileSize(fileSize);
  }

  function handleFileUpload(e) {
    const image = e.target.files[0];
    const fileName = image.name;
    const fileSize = (image.size / 1024).toFixed(1) + "KB";
    onFileChange(image);
    setFileName(fileName);
    setFileSize(fileSize);
  }

  return (
    <div
      className={styles.formContainer}
      onDragEnter={onDragEnter}
      onDragOver={onDragOver}
      onDrop={onFileDrop}
    >
      <div className={styles.uploadFilesContainer}>
        <div className={styles.dragFileArea}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            height="60px"
            viewBox="0 -960 960 960"
            width="60px"
            fill="#29d3d1"
          >
            <path d="M440-320v-326L336-542l-56-58 200-200 200 200-56 58-104-104v326h-80ZM240-160q-33 0-56.5-23.5T160-240v-120h80v120h480v-120h80v120q0 33-23.5 56.5T720-160H240Z" />
          </svg>
          <h3 className={styles.dynamicMessage}> Drag & drop any file here </h3>
          <label className={styles.label}>
            <span className={styles.browseFiles}>
              <input type="file" className={styles.defaultFileInput} onChange={handleFileUpload} />
              <span className={styles.browseFilesText}> or browse file </span>
              <span>from device</span>
            </span>
          </label>
        </div>
        <span className={styles.cannotUploadMessage}>
          <span className={styles.materialIconsOutlined}>error</span> Please select a file first
          <span className={`${styles.materialIconsOutlined} ${styles.cancelAlertButton}`}>
            cancel
          </span>
        </span>
        {fileName && (
          <div className={styles.fileBlock}>
            <div className={styles.fileInfo}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="30px"
                viewBox="0 -960 960 960"
                width="30px"
                fill="#29d3d1"
              >
                <path d="M320-240h320v-80H320v80Zm0-160h320v-80H320v80ZM240-80q-33 0-56.5-23.5T160-160v-640q0-33 23.5-56.5T240-880h320l240 240v480q0 33-23.5 56.5T720-80H240Zm280-520v-200H240v640h480v-440H520ZM240-800v200-200 640-640Z" />
              </svg>
              <span className={styles.fileName}>
                {fileName.substring(0, 150)} {fileName.length > 150 && "..."}
              </span>
              |<span className={styles.fileSize}>{fileSize}</span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="30px"
                viewBox="0 -960 960 960"
                width="30px"
                fill="red"
                onClick={removeFile}
              >
                <path d="M280-120q-33 0-56.5-23.5T200-200v-520h-40v-80h200v-40h240v40h200v80h-40v520q0 33-23.5 56.5T680-120H280Zm400-600H280v520h400v-520ZM360-280h80v-360h-80v360Zm160 0h80v-360h-80v360ZM280-720v520-520Z" />
              </svg>
            </div>

            <div className={styles.progressBar}> </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default ImageUploadContainer;
