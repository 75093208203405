import Video from "../../components/HomeComponents/Video";
import ArtistList from "../../components/HomeComponents/ArtistList";

import styles from "./Home.module.css";

function Home() {
  return (
    <div className={styles.home}>
      <Video videoSrc={"https://storage.googleapis.com/oursongs-media/video.mp4"} />
      <h1>Cherish your loved ones with studio quality custom songs by professional artists</h1>
      <ArtistList />
    </div>
  );
}

export default Home;
