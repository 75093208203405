import styles from "./Video.module.css";
import video from "../../images/video.mp4";
import videoHevc from "../../images/video-mac.mp4";
import { useEffect, useRef } from "react";

function Video({ videoSrc }) {
  const videoRef = useRef(undefined);
  useEffect(() => {
    videoRef.current.defaultMuted = true;
  });
  return (
    <div className={styles.videoContainer}>
      <video autoPlay playsInline muted loop id={styles.video} ref={videoRef}>
        <source src={video} type="video/mp4" />
        <source src={videoHevc} type="video/mp4" />
      </video>
    </div>
  );
}

export default Video;
