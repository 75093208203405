import { Link } from "react-router-dom";
import Logo from "../../images/logo-1.png";
import styles from "./Navbar.module.css";

function Navbar() {
  return (
    <div className={styles.navbar}>
      <div className={styles.logoBanner}>
        <Link to={"/"}>
          <img src={Logo} alt="logo" id={styles.logo} />
        </Link>
      </div>
    </div>
  );
}

export default Navbar;
