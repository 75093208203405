import styles from "./ArtistItem.module.css";
import { LazyLoadImage } from "react-lazy-load-image-component";
import PlaceholderImage from "../../images/Loading....png";

function Img({ thumbnail }) {
  return (
    <LazyLoadImage
      src={thumbnail}
      alt="artist"
      id={styles.artistImage}
      placeholderSrc={PlaceholderImage}
      effect="blur"
    />
  );
}

export default Img;
