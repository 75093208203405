import styles from "./ContactUs.module.css";
import { useRequestSong } from "../../context/RequestOrderContext";

function ContactUs() {
  const { contactUs, handleChange } = useRequestSong();
  function handleSubmit(e) {
    e.preventDefault();
    contactUs();
  }
  return (
    <div className={styles.contactWrap}>
      <h1>Contact US</h1>
      <form className={styles.contactForm} onSubmit={handleSubmit}>
        <input onChange={handleChange} name="email" type="email" placeholder="Your Email..." />
        <input onChange={handleChange} name="subject" type="text" placeholder="Your Subject..." />
        <textarea onChange={handleChange} name="message"></textarea>
        <button type="submit">Submit</button>
      </form>
    </div>
  );
}

export default ContactUs;
