import styles from "./Tos.module.css";

function Tos() {
  return (
    <div className={styles.privacyPolicy}>
      <h1 id={styles.headline}>Terms and Conditions</h1>
      <p className={styles.ppText}>
        Welcome to the Our Song Request service! Before you proceed with using our service, please
        take a moment to review and understand the following terms and conditions. By using our
        service, you agree to comply with these terms and conditions.
      </p>
      <div className={styles.mainText}>
        <div className={styles.paragraph}>
          <h3 id={styles.paragraphHeadline}>1. Service Description:</h3>
          <p id={styles.paragraphText}>
            <p>
              Our Song Request allows customers to request custom songs to celebrate lives based on
              the information and preferences provided by the customer.
            </p>
          </p>
        </div>

        <div className={styles.paragraph}>
          <h3 id={styles.paragraphHeadline}>2. Song Creation Process:</h3>
          <p id={styles.paragraphText}>
            <p>
              a. Request Submission: Customers are required to submit a request form providing
              necessary details about the song, including the purpose, theme, lyrics (if any), and
              any specific requirements or preferences.
            </p>
            <p>
              b. Song Composition: Our team of talented songwriters and musicians will compose an
              original song based on the provided information. The style of the song will be
              determined by the package selected by the customer.
            </p>
            <p>
              c. Review and Revisions: Once the song is completed, a preview will be shared with the
              customer. Minor revisions can be requested by the customer at this stage.{" "}
            </p>
            <p>
              d. Final Delivery: After the customer approves the final version of the song, we will
              deliver it in the chosen format (MP3, WAV, etc.) via email or a secure download link.
              We will make sure you receive your song and can open/download it. We will always make
              sure you have received and love your song!
            </p>
          </p>
        </div>

        <div className={styles.paragraph}>
          <h3 id={styles.paragraphHeadline}>3. Payment and Pricing:</h3>
          <p id={styles.paragraphText}>
            <p>
              a. Pricing: The pricing details can be found on each custom song order form below your
              selected artist. If you choose to add an acrylic block, the price point will change
              with each additional keepsake you choose.
            </p>
            <p>
              b. Payment: Customers are required to make full payment for the service before the
              song creation process begins. We accept various payment methods, including
              credit/debit cards, PayPal, or other secure online payment options
            </p>
          </p>
        </div>

        <div className={styles.paragraph}>
          <h3 id={styles.paragraphHeadline}>4. Copyright and Ownership:</h3>
          <p id={styles.paragraphText}>
            <p>
              a. Originality: We guarantee that all songs created by our team are original and do
              not infringe upon any copyrights or intellectual property rights.
            </p>
            <p>
              b. Ownership: Upon full payment, the customer will own a lifetime license to the
              custom song. This allows the customer to play and share their custom song in any form
              they wish.
            </p>
          </p>
        </div>

        <div className={styles.paragraph}>
          <h3 id={styles.paragraphHeadline}> 5. Customer Responsibilities:</h3>
          <p id={styles.paragraphText}>
            <p>
              a. Accurate Information: Customers are responsible for providing accurate and detailed
              information about their song request to ensure the creation of a personalised and
              satisfactory song.
            </p>
            <p>
              b. Legal Compliance: Customers must ensure that their song requests comply with all
              applicable laws and regulations, including copyright laws, and do not contain any
              offensive, defamatory, or illegal content.
            </p>
          </p>
        </div>

        <div className={styles.paragraph}>
          <h3 id={styles.paragraphHeadline}>6. Refunds and Cancellations:</h3>
          <p id={styles.paragraphText}>
            <p>
              a. Refunds: Due to the personalised nature of our service, refunds will only be issued
              in exceptional circumstances and at our discretion.
            </p>
            <p>
              b. Cancellations: Customers may cancel their song request within 24 hours of payment
              for a full refund. After that, cancellations can only be made on a case-by-case basis.
            </p>
          </p>
        </div>
        <p>
          Please note that these terms and conditions are subject to change without prior notice. It
          is the customer's responsibility to review the most up-to-date version of our terms and
          conditions. If you have any questions or concerns regarding our service or these terms and
          conditions, please feel free to contact our customer support team at
          hello@oursongrequest.com. We are here to assist you and ensure your satisfaction with our
          custom song creation service.
        </p>
      </div>
    </div>
  );
}

export default Tos;
