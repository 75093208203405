import { useRequestSong } from "../../context/RequestOrderContext";

import SpinnerFullPage from "../../components/ReusableComponents/FullPageLoader/SpinnerFullPage";
import ErrorMessage from "../../components/ReusableComponents/ErrorMessage/ErrorMessage";

import styles from "./PaymentPage.module.css";

function PaymentPage() {
  const { loading, errorMessage, requestPayPalPayment, requestStripePayment } = useRequestSong();

  if (loading) return <SpinnerFullPage />;

  return (
    <div>
      <h1>Choose your preferred payment method.</h1>
      <div className={styles.paymentBtns}>
        <button onClick={requestStripePayment} className="standard-btn">
          Pay with credit card
        </button>
        <button onClick={requestPayPalPayment} className="standard-btn">
          Pay with PayPal
        </button>
      </div>
      {errorMessage && (
        <div>
          <ErrorMessage errorMessage={errorMessage} />
        </div>
      )}
    </div>
  );
}

export default PaymentPage;
